<template>
  <div class="form-block form-block--entity">

    <FormTitle>{{$t('entity-new.basic_data')}}</FormTitle>

    <!-- <p><span class="input-block__required">*</span> Indica información requerida</p> -->

    <div class="flex flex--wrap">
      <div class="flex__twothirds">
        <InputText
          name="name"
          :isDisabled="isDisabled"
          :label="$t('common.name')"
          v-model.trim="value.name"
          :isRequired="true"
          :help="$t('common.name_help')"
        ></InputText>
      </div>
      <div class="flex__third">
        <InputText
          name="acronim"
          :isDisabled="isDisabled"
          :label="$t('common.acronim')"
          v-model.trim="value.acronim"
          :isRequired="true"
          :help="$t('common.acronim_help')"
        ></InputText>
      </div>
    </div>

    <div class="flex flex--wrap">
      <div class="flex__third">
        <InputVat
          name="vat_number"
          :isDisabled="isDisabled"
          :label="$t('common.vat_number')"
          v-model.trim="value.vat_number"
          v-model:isValid="isVATValid"
          :help="$t('common.number_help')"
        ></InputVat>
      </div>
      <div class="flex__third">
        <InputEntityType
          name="entity_type"
          :isDisabled="isDisabled || !!value.id"
          :label="$t('common.type')"
          :isRequired="true"
          v-model="value.type"
          :help="$t('common.type_help')"
        ></InputEntityType>
      </div>
      <div class="flex__third">
        <InputEntityLevel
          name="entity_level"
          :isDisabled="isDisabled"
          :label="$t('common.level')"
          :isRequired="true"
          v-model="value.level"
          :help="$t('common.level_help')"
        ></InputEntityLevel>
      </div>
    </div>

    <div class="flex flex--wrap">
      <div class="flex__middle">
        <InputEntitySector
          v-if="value.type === 'PRIC'"
          name="entity_sector"
          :isDisabled="isDisabled"
          :label="$t('common.sector')"
          :isRequired="false"
          v-model="value.sector"
          :help="$t('common.sector_help')"
        ></InputEntitySector>
      </div>
      <div class="flex__middle">
        <InputCnae
          v-if="value.type === 'PRIC'"
          name="entity_cnae"
          :isDisabled="isDisabled"
          :label="$t('common.cnae')"
          :isRequired="false"
          v-model="cnae"
          :help="$t('common.cnae_help')"
        ></InputCnae>
      </div>
    </div>

    <div class="flex flex--wrap">
      <div class="flex__twothirds">
        <InputSlug
          name="slug"
          :isDisabled="isDisabled"
          :label="$t('common.slug')"
          v-model.trim="value.slug"
          v-model:isValid="isSlugValid"
          :help="$t('common.slug_help')"
          :entityid="isEditing ? $route.params.entityid : undefined"
        ></InputSlug>
      </div>
    </div>

    <FormTitle>{{$t('entity-new.economic_data')}}</FormTitle>

    <div class="flex flex--wrap">
      <div class="flex__middle">
        <InputNumber
          name="population"
          :isDisabled="isDisabled"
          :label="getPopulationName()"
          v-model="value.population"
          :help="$t('common.population_help')"
        ></InputNumber>
      </div>
      <div class="flex__middle">
        <InputNumber
          name="income"
          :isDisabled="isDisabled"
          :label="getIncomeName()"
          v-model="value.income"
          :help="getIncomeName()"
        ></InputNumber>
      </div>
    </div>

    <FormTitle>{{$t('entity-new.categories')}}</FormTitle>

    <InputText
      name="categories"
      :isDisabled="isDisabled"
      :label="$t('common.categories')"
      v-model.trim="categories"
      :help="$t('common.categories_help')"
    ></InputText>

    <FormTitle>{{$t('entity-new.contact_data')}}</FormTitle>

    <div class="flex flex--wrap">
      <div class="flex__middle">
        <InputText
          name="web"
          :isDisabled="isDisabled"
          :label="$t('common.web')"
          v-model.trim="value.web"
          :runValidations="true"
          :isRequired="false"
          :validators="urlValidators"
          :help="$t('common.web_help')">
          <span v-if="value.web && !urlValidators.every((v) => v(value.web))">
            {{$t('error.invalid_url')}}
          </span>
        </InputText>
      </div>

      <div class="flex__middle">
        <InputEmail
          name="email"
          :isDisabled="isDisabled"
          :label="$t('common.email')"
          :runValidations="true"
          :isRequired="false"
          v-model.trim="value.email"
          :help="$t('common.email_help')"
        ></InputEmail>
      </div>
    </div>

    <FormTitle>{{$t('entity-new.social_media')}}</FormTitle>

    <InputSocialMedia
      name="socials_media"
      :label="$t('common.social_media')"
      v-model="value.social_media"
      :isDisabled="isDisabled"
      :help="$t('common.social_media_help')">
    </InputSocialMedia>

    <FormTitle>{{$t('entity-new.address_data')}}</FormTitle>

    <div class="flex flex--wrap">
      <div class="flex__middle">
        <InputText
          name="address_legal"
          :isDisabled="isDisabled"
          :label="$t('common.legal_address')"
          v-model.trim="value.address_legal"
          :help="$t('common.legal_address_help')"
        ></InputText>
      </div>
      <div class="flex__middle">
        <InputText
          name="address"
          :isDisabled="isDisabled"
          :label="$t('common.address')"
          v-model.trim="value.address"
          :isRequired="false"
          :help="$t('common.address_help')">
          <template #link>
            <a
              v-if="value.address_legal && value.address_legal !== value.address"
              @click="value.address = value.address_legal"
            >{{$t('common.address_copy')}}</a>
          </template>
        </InputText>
      </div>
    </div>

    <InputCoordinates
      name="coordinates"
      :isDisabled="isDisabled"
      :label="$t('common.location')"
      v-model="coordinates"
    ></InputCoordinates>

    <FormTitle>{{$t('entity-new.logo')}}</FormTitle>

    <InputImage
      name="logo"
      :label="$t('common.logo')"
      v-model="image"
      :options="imageOptions"
      :isDisabled="isDisabled"
    ></InputImage>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Check from '@/services/check/Check';
import InputEmail from '@/components/inputs/email/InputEmail.vue';
import InputCnae from '@/components/inputs/cnae/InputCnae.vue';
import InputVat from '@/components/inputs/vat/InputVat.vue';
import InputSlug from '@/components/inputs/slug/InputSlug.vue';
import InputEntityType from '@/components/inputs/entity-type/InputEntityType.vue';
import InputEntitySector from '@/components/inputs/entity-sector/InputEntitySector.vue';
import InputEntityLevel from '@/components/inputs/entity-level/InputEntityLevel.vue';
import InputCoordinates from '@/components/inputs/coordinates/InputCoordinates.vue';
import InputSocialMedia from '@/components/inputs/social-media/InputSocialMedia.vue';

export default {
  name: 'FormEntity',
  components: {
    InputEmail,
    InputCnae,
    InputVat,
    InputEntitySector,
    InputEntityType,
    InputEntityLevel,
    InputCoordinates,
    InputSocialMedia,
    InputSlug,
  },
  emits: [
    'update:isValid',
  ],
  data() {
    return {
      isEditing: !!this.$route.params.entityid,
      isNameValid: undefined,
      isVATValid: undefined,
      isSlugValid: undefined,
      urlValidators: [Check.isValidUrl],
      imageOptions: {
        viewport: { width: 300, height: 100 },
        radius: 0,
      },
    };
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => ({
        name: '',
        acronim: '',
        cif: '',
        type: '',
        sector: '',
        level: '',
        cnae: '',
        home: '',
        address: '',
        web: '',
        email: '',
        coordinates: [0, 0],
      }),
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['universeIsLoaded']),
    image: {
      get() {
        return this.modelValue?.logo;
      },
      set(value) {
        this.value.logo = value;
      },
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    coordinates: {
      get() {
        if (this.modelValue.coordinates && Array.isArray(this.modelValue.coordinates)) {
          return this.modelValue.coordinates;
        }
        if (this.modelValue.coordinates) {
          const regExp = /\(([^)]+)\)/;
          const coord = regExp.exec(this.modelValue.coordinates)[0];
          return coord.slice(1, -1).split(' ');
        }
        return [];
      },
      set(value) {
        this.value.coordinates = value;
      },
    },
    categories: {
      get() {
        return Array.isArray(this.modelValue.categories)
          ? this.modelValue.categories.join(', ')
          : [];
      },
      set(value) {
        this.value.categories = value.split(',')
          .map((cat) => cat.trim())
          .filter((cat) => cat);
      },
    },
    cnae: {
      get() {
        if (!this.modelValue.cnae) return undefined;
        return typeof this.modelValue.cnae === 'object'
          ? this.modelValue.cnae.id
          : this.modelValue.cnae;
      },
      set(value) {
        if (value) {
          this.value.cnae = value;
        }
      },
    },
    isInvalid() {
      return !this.value.name
        || !this.value.acronim
        || !this.isVATValid
        || !this.isSlugValid
        || !this.value.level
        || !this.value.type;
    },
  },
  methods: {
    updateIsValid() {
      this.$emit('update:isValid', !this.isInvalid);
    },
    getPopulationName() {
      if (this.value.type === 'PRIC') return this.$t('common.employees');
      if (this.value.type === 'EDU') return this.$t('common.students');
      if (this.value.type === 'TER') return this.$t('common.employees');
      return this.$t('common.population');
    },
    getIncomeName() {
      if (this.value.type === 'PRIC') return this.$t('common.annual_billing');
      return this.$t('common.budget');
    },
  },
  watch: {
    isInvalid: 'updateIsValid',
  },
};
</script>
