<template>
  <InputBlock
    :label="label"
    :name="name"
    :help="help"
    :isRequired="isRequired"
    :isDisabled="isDisabled"
    :isInvalid="isInvalid"
    :isWarning="isUgly"
    :classes="[
      'input-block--text',
      'input-block--with-prefix',
      `input-block--name-${name}`,
      ...classes,
    ]">
    <div class="input-box">
      <div class="prefix">
        <span>{{getDomain()}}</span>
      </div>
      <input
        :id="name"
        v-model="value"
        ref="input"
        type="text"
        class="slug"
        :placeholder="placeholder"
        :disabled="isDisabled"
        :required="isRequired"
        @input="onInput"
        @keyup.enter="$emit('enter', value)">
    </div>

    <template #alerts>
      <div v-if="isUgly" class="warn">Formato recomendado: <strong>{{clean(value)}}</strong></div>
      <div v-if="!isUnique">{{$t('error.slug_already_exists')}}</div>
      <div v-else-if="isInvalid">{{$t('error.incorrect_slug')}}</div>
      <slot></slot>
    </template>

  </InputBlock>
</template>

<script>
import UrlService from '@/services/url/UrlService';
import debounce from 'debounce';
import Check from '@/services/check/Check';
import Parser from '@/services/parser/Parser';
import InputMixin from '../InputMixin';
import InputBlock from '../InputBlock.vue';

const urlService = new UrlService();

export default {
  name: 'InputSlug',
  mixins: [
    InputMixin,
  ],
  emits: [
    'update:isValid',
  ],
  components: {
    InputBlock,
  },
  data() {
    return {
      isUnique: true,
    };
  },
  props: {
    entityid: {
      default: undefined,
    },
  },
  computed: {
    validSlug() {
      return !(this.isUgly || this.isInvalid)
    },
    isInvalid() {
      if (!this.value || this.value==='') return false;
      return !this.isUnique || this.clean(this.value) === '';
    },
    isUgly() {
      if (!this.value || this.value==='') return false;
      return !Check.isValidSlug(this.value) && this.clean(this.value) !== '';
    },
  },
  mounted() {
    this.updateIsValid();
  },
  methods: {
    getDomain() {
      return urlService.getDomainUrl;
    },
    onInput(event) {
      this.value = event.target.value.toLowerCase();
      this.$emit('input', event.target.value.toLowerCase());
    },
    clean(value) {
      return Parser.prettySlug(value);
    },
    update: debounce((func, arg) => { func(arg); }, 200),
    checkSlug(slug) {
      if (!slug) return;
      this.$store.dispatch('post', {
        url: 'checkentityslug',
        data: { slug },
      }).then((response) => {
        this.isUnique = !response.data.entity
          || response.data.entity.toString() === this.entityid;
      });
    },
    updateIsValid() {
      this.$emit('update:isValid', this.validSlug);
    },
  },
  watch: {
    validSlug: 'updateIsValid',
    value(val) {
      this.update(this.checkSlug, val);
    },
  },
};
</script>

