<template>
  <InputBlock
    :label="label"
    :name="name"
    :help="help"
    :isRequired="isRequired && !isDisabled"
    :classes="[
      'input-block--coordinates',
      `input-block--name-${name}`,
      ...classes,
    ]">

    <div class="coors-block">
      <div class="coors-block__wrap">
        <InputNumber
          name="latitude"
          :isDisabled="isDisabled"
          :label="$t('common.latitude')"
          v-model="latitude"
          :classes="['input-block--narrow']"
        ></InputNumber>
        <InputNumber
          name="longitude"
          :isDisabled="isDisabled"
          :label="$t('common.longitude')"
          v-model="longitude"
          :classes="['input-block--narrow']"
        ></InputNumber>
      </div>
    </div>

    <MapPoints
      :lat="center.lat"
      :lng="center.lng"
      :zoom="center.zoom"
      :points="points"
      @move="onMove"
      @dblclick="onDoubleClick"
      v-if="points.length > 0 || true"></MapPoints>

    <template #alerts>
      <slot></slot>
    </template>

  </InputBlock>
</template>

<script>
import MapPoints from '@/components/layout/map-points/MapPoints.vue';
import InputMixin from '../InputMixin';
import InputBlock from '../InputBlock.vue';

export default {
  name: 'InputCoordinates',
  mixins: [
    InputMixin,
  ],
  components: {
    InputBlock,
    MapPoints,
  },
  data() {
    return {
      points: [],
      center: {
        lng: -3.7012579,
        lat: 40.4169497,
        zoom: 5,
      },
    };
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
      default: () => ([0, 0]),
    },
  },
  computed: {
    latitude: {
      get() {
        return this.modelValue.length === 2 && this.modelValue[1] ? this.modelValue[1] : 0;
      },
      set(value) {
        if (this.points.length === 1) {
          this.points[0].coordinates[1] = value;
          this.$emit('update:modelValue', this.value);
        } else {
          this.points = [{
            id: 1,
            name: `${this.center.lng} ${value}`,
            coordinates: [this.center.lng, value],
          }];
          this.$emit('update:modelValue', this.value);
        }
      },
    },
    longitude: {
      get() {
        return this.modelValue.length === 2 && this.modelValue[0] ? this.modelValue[0] : 0;
      },
      set(value) {
        if (this.points.length === 1) {
          this.points[0].coordinates[0] = value;
          this.$emit('update:modelValue', this.value);
        } else {
          this.points = [{
            id: 1,
            name: `${value} ${this.center.lat}`,
            coordinates: [value, this.center.lat],
          }];
          this.$emit('update:modelValue', this.value);
        }
      },
    },
  },
  methods: {
    onDoubleClick(location) {
      this.value = [location.lng, location.lat];
      this.points = [{
        id: 1,
        name: `${location.lng} ${location.lat}`,
        coordinates: [location.lng, location.lat],
      }];
    },
    onMove(location) {
      this.center = { ...location };
    },
  },
};
</script>

<style lang="scss">
.input-block--coordinates {
  position: relative;
  .coors-block {
    position: absolute;
    z-index: 999;
    top: 42px;
    right: 0;
    width: 360px;
    &__wrap {
      display: flex;
      justify-content: flex-end;
    }
    .input-block {
      background: var(--background-color-default);
    }
  }
}
</style>
