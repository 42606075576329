<template>
  <InputSelect
    v-if="this.cnaesList.length"
    :name="name"
    :classes="classes"
    :label="label"
    :help="help"
    :isDisabled="isDisabled"
    :isRequired="isRequired"
    :focusOnMount="focusOnMount"
    v-model="value"
    :options="cnaesList"
  ></InputSelect>
</template>

<script>
import { mapGetters } from 'vuex';
import InputMixin from '../InputMixin';

export default {
  name: 'InputCnae',
  mixins: [
    InputMixin,
  ],
  computed: {
    ...mapGetters(['cnaes']),
    cnaesList() {
      return this.cnaes.length
        ? this.cnaes.map((d) => ({
          v: d.id,
          n: `${d.code} - ${d.desc} - ${d.group}`,
        })) : [];
    },
  },
  mounted() {
    if (!this.cnaes.length) {
      this.$store.dispatch('list', {
        model: 'cnae',
        updateModel: true,
        params: { limit: 1000 },
      });
    }
  },
};
</script>
