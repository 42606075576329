<template>
  <div>
    <div v-for="(social, i) in value" :key="i">

      <div class="flex flex--wrap">
        <div class="flex__third">
          <InputSocialPlatform
            :name="`socialplatform-${i}`"
            :isDisabled="isDisabled"
            :label="$t('common.platform')"
            v-model="value[i].platform"
          ></InputSocialPlatform>
        </div>
        <div class="flex__twothirds">
          <InputText
            :name="`sociallink-${i}`"
            :isDisabled="isDisabled"
            :label="$t('common.url')"
            v-model="value[i].url"
            :runValidations="true"
            :validators="urlValidators">
            <span v-if="value[i].url && !urlValidators.every((v) => v(value[i].url))">
              {{$t('error.invalid_url')}}
            </span>
          </InputText>
        </div>
      </div>
    </div>

    <Button v-show="!isDisabled" @onclick="addSocialMedia()">
      <span v-if="value.length > 0">{{$t('common.add_another')}}</span>
      <span v-else>{{$t('common.add')}}</span>
    </Button>

  </div>
</template>

<script>
import Check from '@/services/check/Check';
import InputMixin from '../InputMixin';
import InputSocialPlatform from '../social-platform/InputSocialPlatform.vue';

export default {
  name: 'InputSocialMedia',
  mixins: [
    InputMixin,
  ],
  components: {
    InputSocialPlatform,
  },
  data() {
    return {
      urlValidators: [Check.isValidUrl],
    };
  },
  computed: {
    value: {
      get() {
        return Array.isArray(this.modelValue)
          ? this.modelValue
          : [];
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    addSocialMedia() {
      this.value = [...this.value, { platform: '', url: '' }];
    },
  },
};
</script>
