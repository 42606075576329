<template>
  <Page
    :title="entity?.id ? $t('entity-new.title_edit') : $t('entity-new.title_new')"
    :classes="['page--narrow', 'page--entity-new']">

    <Form :classes="['form--entitynew']">
      <FormEntity
        v-model="entity"
        v-model:isValid="isValid"
      ></FormEntity>

      <template #buttons>
        <Button
          :isLoading="isLoading"
          :isDisabled="!isValid"
          :classes="['btn--accent']"
          @onclick="createEntity">
          <span v-if="!entity.id">{{$t('common.create_entity')}}</span>
          <span v-else>{{$t('common.update_entity')}}</span>
        </Button>
      </template>
    </Form>

  </Page>
</template>

<script>
import { mapGetters } from 'vuex';
import FormEntity from '@/components/forms/entity/FormEntity.vue';

export default {
  name: 'EntityEditPage',
  components: {
    FormEntity,
  },
  data() {
    return {
      isValid: false,
      isLoading: false,
    };
  },
  mounted() {
    if (!this.$route.params?.entityid) {
      this.$store.dispatch('emptyModel', 'entity');
    }
  },
  computed: {
    ...mapGetters(['entity']),
  },
  methods: {
    createEntity() {
      if (!this.isValid) return;

      const data = { ...this.entity };
      if (typeof data.cnae === 'object' && data.cnae) {
        data.cnae = data.cnae.id;
      }
      if (!data.categories) data.categories = [];
      if (!data.social_media) data.social_media = [];
      if (typeof data.coordinates === 'string') {
        const regExp = /\(([^)]+)\)/; // Match string inside parenthesis
        const coords = regExp.exec(data.coordinates)[0];
        data.coordinates = coords.slice(1, -1).split(' ').map((n) => +n);
      }

      const rData = this.entity.id
        ? { model: 'entity', id: +this.entity.id, data }
        : { model: 'entity', data };

      this.$store.dispatch(this.entity.id ? 'partial_update' : 'create', rData).then(() => {
        this.$store.dispatch('meeseeks', {
          type: 'success',
          message: this.entity.id
            ? this.$t('common.entity_updated')
            : this.$t('common.entity_created'),
        });
        this.$store.dispatch('getSelf');
        this.$router.push({
          name: 'new-report',
          query: { entity: this.entity.id },
          params: { locale: this.$i18n.locale },
        });
      }).catch(() => {
        this.$store.dispatch('meeseeks', {
          message: this.$t('error.cant_update_data'),
          type: 'error',
        });
      });
    },
  },
};
</script>
