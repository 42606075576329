<template>
  <InputBlock
    :label="label"
    :name="name"
    :help="help"
    :isRequired="isRequired"
    :isDisabled="isDisabled"
    :isInvalid="isInvalid"
    :isWarning="isUgly"
    :classes="[
      'input-block--text',
      'input-block--vat',
      `input-block--name-${name}`,
      ...classes,
    ]">

    <input
      :id="name"
      v-model="value"
      ref="input"
      type="text"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :required="isRequired"
      @input="onInput"
      @keyup.enter="$emit('enter', value)">

    <template #alerts>
      <div v-if="isUgly" class="warn">Formato recomendado: <strong>{{clean(value)}}</strong></div>
      <div v-if="!isUnique">{{$t('error.vat_already_exists')}}</div>
      <slot></slot>
    </template>

  </InputBlock>
</template>

<script>
import debounce from 'debounce';
import Check from '@/services/check/Check';
import Parser from '@/services/parser/Parser';
import InputMixin from '../InputMixin';
import InputBlock from '../InputBlock.vue';

export default {
  name: 'InputVat',
  mixins: [
    InputMixin,
  ],
  emits: [
    'update:isValid',
  ],
  components: {
    InputBlock,
  },
  data() {
    return {
      isUnique: true,
    };
  },
  props: {
    entityid: {
      default: undefined,
    },
  },
  computed: {
    isInvalid() {
      return this.isRequired && this.value !== ''
        ? !this.isUnique
        : false;
    },
    isUgly() {
      if (!this.value) return false;
      return !Check.isBeautifulVAT(this.value);
    },
  },
  mounted() {
    this.updateIsValid();
  },
  methods: {
    onInput(event) {
      this.value = event.target.value.toUpperCase();
      this.$emit('input', event.target.value.toUpperCase());
    },
    clean(value) {
      return Parser.prettyVAT(value);
    },
    update: debounce((func, arg) => { func(arg); }, 200),
    checkVatNumber(number) {
      if (!number) return;
      this.$store.dispatch('post', {
        url: 'checkentityvat',
        data: { number },
      }).then((response) => {
        this.isUnique = !response.data.entity
          || response.data.entity === this.entityid;
      });
    },
    updateIsValid() {
      this.$emit('update:isValid', !this.isInvalid);
    },
  },
  watch: {
    isInvalid: 'updateIsValid',
    value(val) {
      this.update(this.checkVatNumber, val);
    },
  },
};
</script>
